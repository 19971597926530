/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import { Layout, SEO, Section } from "components"

const ErrorPage = ({ pageContext }) => {
  return (
    <Layout pageContext={pageContext}>
      <SEO title="Okta | The Identity Standard" />
      <Section overrideStyles={{ minHeight: ["auto", "calc(100vh - 292px)"] }}>
        <h1 sx={{ mb: "25px" }}>Page not found</h1>
        <h4 sx={{ mb: "15px" }}>
          The web page or file you were trying to reach cannot be found. It may
          have been moved to a different location or removed because the
          information it contained is no longer valid.
        </h4>
        <p>Common errors that can result in broken links include:</p>
        <ul>
          <li sx={{ mb: "10px" }}>
            Truncated URLs. Some email applications may break a URL due to line
            breaks. You may need to cut and paste a URL from your email
            application into your browser's location field.
          </li>
          <li>
            Case Sensitivity. URLs are case sensitive. "File.html" and
            "file.html" are not considered the same file by the server. Please
            verify that the URL is entered correctly as printed.
          </li>
        </ul>
        <p>
          Please <a href="mailto:info@okta.com">email</a> your
          feedback/comments.
        </p>
      </Section>
    </Layout>
  )
}

export default ErrorPage
